/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.01.15.16.48
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContentTypeEnum } from './ContentTypeEnum';
import {
    ContentTypeEnumFromJSON,
    ContentTypeEnumFromJSONTyped,
    ContentTypeEnumToJSON,
} from './ContentTypeEnum';

/**
 * 
 * @export
 * @interface ResultBlock
 */
export interface ResultBlock {
    /**
     * 
     * @type {number}
     * @memberof ResultBlock
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ResultBlock
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof ResultBlock
     */
    scenario: number;
    /**
     * 
     * @type {ContentTypeEnum}
     * @memberof ResultBlock
     */
    contentType: ContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ResultBlock
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof ResultBlock
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof ResultBlock
     */
    description: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ResultBlock
     */
    legend: { [key: string]: any; };
}

/**
 * Check if a given object implements the ResultBlock interface.
 */
export function instanceOfResultBlock(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "scenario" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "order" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "legend" in value;

    return isInstance;
}

export function ResultBlockFromJSON(json: any): ResultBlock {
    return ResultBlockFromJSONTyped(json, false);
}

export function ResultBlockFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultBlock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'scenario': json['scenario'],
        'contentType': ContentTypeEnumFromJSON(json['content_type']),
        'content': json['content'],
        'order': json['order'],
        'description': json['description'],
        'legend': json['legend'],
    };
}

export function ResultBlockToJSON(value?: ResultBlock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'scenario': value.scenario,
        'content_type': ContentTypeEnumToJSON(value.contentType),
        'content': value.content,
        'order': value.order,
        'description': value.description,
        'legend': value.legend,
    };
}

