/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.01.15.16.48
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TopologyListResponse,
  TopologyRetreiveResponse,
} from '../models';
import {
    TopologyListResponseFromJSON,
    TopologyListResponseToJSON,
    TopologyRetreiveResponseFromJSON,
    TopologyRetreiveResponseToJSON,
} from '../models';

export interface ApiTopologyRetrieveRequest {
    id: number;
    xMax: number;
    xMin: number;
    yMax: number;
    yMin: number;
}

/**
 * 
 */
export class TopologyApi extends runtime.BaseAPI {

    /**
     * Endpoint for receiving a list of topologies available
     */
    async apiTopologyListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TopologyListResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/topology/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TopologyListResponseFromJSON));
    }

    /**
     * Endpoint for receiving a list of topologies available
     */
    async apiTopologyList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TopologyListResponse>> {
        const response = await this.apiTopologyListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving a topology
     */
    async apiTopologyRetrieveRaw(requestParameters: ApiTopologyRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TopologyRetreiveResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTopologyRetrieve.');
        }

        if (requestParameters.xMax === null || requestParameters.xMax === undefined) {
            throw new runtime.RequiredError('xMax','Required parameter requestParameters.xMax was null or undefined when calling apiTopologyRetrieve.');
        }

        if (requestParameters.xMin === null || requestParameters.xMin === undefined) {
            throw new runtime.RequiredError('xMin','Required parameter requestParameters.xMin was null or undefined when calling apiTopologyRetrieve.');
        }

        if (requestParameters.yMax === null || requestParameters.yMax === undefined) {
            throw new runtime.RequiredError('yMax','Required parameter requestParameters.yMax was null or undefined when calling apiTopologyRetrieve.');
        }

        if (requestParameters.yMin === null || requestParameters.yMin === undefined) {
            throw new runtime.RequiredError('yMin','Required parameter requestParameters.yMin was null or undefined when calling apiTopologyRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.xMax !== undefined) {
            queryParameters['x_max'] = requestParameters.xMax;
        }

        if (requestParameters.xMin !== undefined) {
            queryParameters['x_min'] = requestParameters.xMin;
        }

        if (requestParameters.yMax !== undefined) {
            queryParameters['y_max'] = requestParameters.yMax;
        }

        if (requestParameters.yMin !== undefined) {
            queryParameters['y_min'] = requestParameters.yMin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/topology/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyRetreiveResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiving a topology
     */
    async apiTopologyRetrieve(requestParameters: ApiTopologyRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TopologyRetreiveResponse> {
        const response = await this.apiTopologyRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
