/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.01.15.16.48
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VertexPointGeom } from './VertexPointGeom';
import {
    VertexPointGeomFromJSON,
    VertexPointGeomFromJSONTyped,
    VertexPointGeomToJSON,
} from './VertexPointGeom';

/**
 * 
 * @export
 * @interface VertexCreateRequest
 */
export interface VertexCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof VertexCreateRequest
     */
    scenario: number;
    /**
     * 
     * @type {VertexPointGeom}
     * @memberof VertexCreateRequest
     */
    geom: VertexPointGeom;
}

/**
 * Check if a given object implements the VertexCreateRequest interface.
 */
export function instanceOfVertexCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "scenario" in value;
    isInstance = isInstance && "geom" in value;

    return isInstance;
}

export function VertexCreateRequestFromJSON(json: any): VertexCreateRequest {
    return VertexCreateRequestFromJSONTyped(json, false);
}

export function VertexCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VertexCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scenario': json['scenario'],
        'geom': VertexPointGeomFromJSON(json['geom']),
    };
}

export function VertexCreateRequestToJSON(value?: VertexCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scenario': value.scenario,
        'geom': VertexPointGeomToJSON(value.geom),
    };
}

