/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.01.15.16.48
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectListScenarioResponse } from './ProjectListScenarioResponse';
import {
    ProjectListScenarioResponseFromJSON,
    ProjectListScenarioResponseFromJSONTyped,
    ProjectListScenarioResponseToJSON,
} from './ProjectListScenarioResponse';
import type { ScenarioTypeEnum } from './ScenarioTypeEnum';
import {
    ScenarioTypeEnumFromJSON,
    ScenarioTypeEnumFromJSONTyped,
    ScenarioTypeEnumToJSON,
} from './ScenarioTypeEnum';

/**
 * 
 * @export
 * @interface ProjectListResponse
 */
export interface ProjectListResponse {
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponse
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponse
     */
    name: string;
    /**
     * 
     * @type {ScenarioTypeEnum}
     * @memberof ProjectListResponse
     */
    scenarioType?: ScenarioTypeEnum;
    /**
     * 
     * @type {Array<ProjectListScenarioResponse>}
     * @memberof ProjectListResponse
     */
    scenarioList: Array<ProjectListScenarioResponse>;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponse
     */
    baseTopology: number;
    /**
     * 
     * @type {Date}
     * @memberof ProjectListResponse
     */
    readonly timestampLastModified: Date;
}

/**
 * Check if a given object implements the ProjectListResponse interface.
 */
export function instanceOfProjectListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "scenarioList" in value;
    isInstance = isInstance && "baseTopology" in value;
    isInstance = isInstance && "timestampLastModified" in value;

    return isInstance;
}

export function ProjectListResponseFromJSON(json: any): ProjectListResponse {
    return ProjectListResponseFromJSONTyped(json, false);
}

export function ProjectListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'scenarioType': !exists(json, 'scenario_type') ? undefined : ScenarioTypeEnumFromJSON(json['scenario_type']),
        'scenarioList': ((json['scenario_list'] as Array<any>).map(ProjectListScenarioResponseFromJSON)),
        'baseTopology': json['base_topology'],
        'timestampLastModified': (new Date(json['timestamp_last_modified'])),
    };
}

export function ProjectListResponseToJSON(value?: ProjectListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'scenario_type': ScenarioTypeEnumToJSON(value.scenarioType),
        'scenario_list': ((value.scenarioList as Array<any>).map(ProjectListScenarioResponseToJSON)),
        'base_topology': value.baseTopology,
    };
}

