/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.01.15.16.48
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TopologyPointGeom } from './TopologyPointGeom';
import {
    TopologyPointGeomFromJSON,
    TopologyPointGeomFromJSONTyped,
    TopologyPointGeomToJSON,
} from './TopologyPointGeom';

/**
 * 
 * @export
 * @interface TopologyVertice
 */
export interface TopologyVertice {
    /**
     * 
     * @type {number}
     * @memberof TopologyVertice
     */
    id: number;
    /**
     * 
     * @type {TopologyPointGeom}
     * @memberof TopologyVertice
     */
    geom: TopologyPointGeom;
}

/**
 * Check if a given object implements the TopologyVertice interface.
 */
export function instanceOfTopologyVertice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "geom" in value;

    return isInstance;
}

export function TopologyVerticeFromJSON(json: any): TopologyVertice {
    return TopologyVerticeFromJSONTyped(json, false);
}

export function TopologyVerticeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyVertice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'geom': TopologyPointGeomFromJSON(json['geom']),
    };
}

export function TopologyVerticeToJSON(value?: TopologyVertice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'geom': TopologyPointGeomToJSON(value.geom),
    };
}

