/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.01.15.16.48
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `APARTMENT` - APARTMENT
 * * `TARRACED_HOUSE` - TARRACED_HOUSE
 * * `SEMI_DETACHED_HOUSE` - SEMI_DETACHED_HOUSE
 * * `DETACHED_HOUSE` - DETACHED_HOUSE
 * @export
 */
export const HousingTypeEnum = {
    Apartment: 'APARTMENT',
    TarracedHouse: 'TARRACED_HOUSE',
    SemiDetachedHouse: 'SEMI_DETACHED_HOUSE',
    DetachedHouse: 'DETACHED_HOUSE'
} as const;
export type HousingTypeEnum = typeof HousingTypeEnum[keyof typeof HousingTypeEnum];


export function HousingTypeEnumFromJSON(json: any): HousingTypeEnum {
    return HousingTypeEnumFromJSONTyped(json, false);
}

export function HousingTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): HousingTypeEnum {
    return json as HousingTypeEnum;
}

export function HousingTypeEnumToJSON(value?: HousingTypeEnum | null): any {
    return value as any;
}

